import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import BannerCareer from "../components/banner/career"
import BlockRichText from "../components/block-rich-text"
import Slider from "react-slick/lib/slider"
import { appendDots } from "../components/slider/slider-dots"
import { useRef } from "react"
import SliderNav from "../components/slider/slider-nav"
import { faChevronDown } from '@fortawesome/free-solid-svg-icons'
import GoogleMap from "../components/google-map"
import JobApplicationForm from "../components/job-application-form"
import 'slick-carousel/slick/slick.css'
import Instagram from "../components/instagram"
import { useTranslation } from "gatsby-plugin-react-i18next"
import { IconButton } from "../components/icon-link-button"

function Job({
  title,
  body,
}) {
  const { t } = useTranslation()

  function onClickApply() {
    if (!document) {
      return;
    }
    document!.querySelector('.form-section')!.scrollIntoView({ behavior: 'smooth' });
  }

  return (
    <div className="px-6">
      <div className="job-post">
        <h3 className="border-b-2 border-primary text-[40px] text-primary font-bold p-6 leading-none">
          {title}
        </h3>
        <BlockRichText className="p-6 space-y-4" data={body.data.body} />
        <div className="p-6 text-center">
          <IconButton
            icon={faChevronDown}
            onClick={onClickApply}
          >
            {t('apply')}
          </IconButton>
        </div>
      </div>
    </div>
  )
}

function Jobs({
  data,
  ...rest
}) {
  const slider = useRef(null)

  if (data.length === 0) {
    return null
  }

  if (data.length === 1) {
    return <Job {...data[0]} />
  }

  const sliderSettings = {
    className: [
      'slider',
      'slider-reversed-dot',
    ].join(' '),

    dots: true,
    arrows: false,
    appendDots,
    slidesToShow: 2,
    responsive: [
      {
        breakpoint: 1023,
        settings: {
          slidesToShow: 1,
        }
      }
    ]
  }

  const sliderNavClassName = [
    'absolute',
    'top-[150px]',
    'text-[100px]',
    'text-primary',
  ]

  return (
    <div className="relative px-5">
      <SliderNav
        className={[...sliderNavClassName, 'left-0'].join(' ')}
        onClick={() => slider!.current.slickPrev()}
        direction="left"
      >
        &lt;
      </SliderNav>
      <Slider key="career-slick" ref={slider} {...sliderSettings}>
        { data.map(jobData => (<Job key={`job-${jobData.id}`} {...jobData} {...rest} />)) }
      </Slider>
      <SliderNav
        className={[...sliderNavClassName, 'right-0'].join(' ')}
        onClick={() => slider!.current.slickNext()}
        direction="right"
      >
        &gt;
      </SliderNav>
    </div>
  )
}

function CareerPage({ data, pageContext }) {
  const { allStrapiJob, strapiGlobal } = data
  const { t } = useTranslation()

  const seo = {
    metaTitle: `${t('join')} advisoryapps`,
    lang: pageContext.strapiLangKey,
  }

  const form = useRef(null)

  return (
    <Layout>
      <Seo seo={seo} />
      <BannerCareer />
      <main>
        <section className="container mb-20">
          <Jobs {...{ data: allStrapiJob.nodes, form, }} />
        </section>
      </main>
      <Instagram />
      <GoogleMap />
      <JobApplicationForm {...{
        forwardRef: form,
        data: allStrapiJob.nodes,
        t,
        contactDetails: strapiGlobal.contactDetails,
      }} />
    </Layout>
  )
}

export const query = graphql`
  query(
    $language: String!
    $strapiLangKey: String!
  ) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      ...Locales
    }
    strapiGlobal(locale: {eq: $strapiLangKey}) {
      ...ContactDetails
    }
    allStrapiJob(
      filter: {
        locale: { eq: $strapiLangKey }
      }
    ) {
      nodes {
        id
        title
        body {
          data {
            body
          }
        }
      }
    }
  }
`

export default CareerPage
