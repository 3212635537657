import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { graphql, useStaticQuery } from 'gatsby';
import React, { MutableRefObject, useRef } from 'react'
import { faChevronRight, faChevronDown } from '@fortawesome/free-solid-svg-icons'
import ContactAddress from './contact/contact-address';
import ContactPhone from './contact/contact-phone';
import ContactEmail from './contact/contact-email';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import FormErrorMessages from './form/form-error-messages';
import { formSubmitErrorMessage, useSendEmail } from './form/email';
import FormSuccessMessage from './form/form-success-message';
import { IconButton } from './icon-link-button';

type Props = {
  data: any;
  forwardRef?: MutableRefObject<any>;
  t: any;
}

const acceptedFileFormat = [
  '.doc',
  '.docx',
  '.pdf',
  'image/jpeg',
  'image/png',
]

const schema = yup.object({
  name: yup.string().required(),
  mobile: yup.string().required(),
  email: yup.string().email().required(),
  attachment: yup.mixed().test(
    'fileType',
    'Unsupported file format',
    file => file && file.length > 0 ? acceptedFileFormat.includes(file[0].type) : true,
  ),
  message: yup.string().required(),
});

function Form({
  errors,
  forwardRef,
  jobList,
  loading,
  onSubmit,
  register,
  visible = true,
  t,
}) {
  if (!visible) {
    return null;
  }

  return (
    <>
      <FormErrorMessages {...{ errors }} />
      <form ref={forwardRef} className="grid lg:grid-cols-2 gap-3" onSubmit={onSubmit}>
        <div className="flex flex-col space-y-3">
          <input
            className="form-input"
            type="text"
            placeholder={`${t('name')}*`}
            {...register("name")}
          />
          <input
            className="form-input"
            type="text"
            placeholder={`${t('mobile')}*`}
            {...register('mobile')}
          />
          <input
            className="form-input"
            type="email"
            placeholder={`${t('email')}*`}
            {...register('email')}
          />
          <input
            className="form-input"
            type="file"
            placeholder='attachment'
            accept={acceptedFileFormat.join(',')}
            {...register('attachment')}
          />
        </div>
        <div className="flex flex-col space-y-3">
          <div className="relative">
            <select
              className="form-input w-full"
              {...register('position')}
            >
              { jobList.map(({ id, title }) => <option key={`option-${id}`}>{title}</option>) }
            </select>
            <FontAwesomeIcon className="pointer-events-none absolute right-4 top-0 bottom-0 m-auto" icon={faChevronDown} />
          </div>
          <textarea
            className="form-input pb-[1.7rem]"
            placeholder={`${t('message')}*`}
            rows={6}
            {...register('message')}
          />
        </div>
        <div>
          <IconButton
            type="submit"
            loading={loading}
            icon={faChevronRight}
          >
            {t('apply')}
          </IconButton>
        </div>
      </form>
    </>
  )
}

function JobApplicationForm({
  contactDetails,
  data,
  forwardRef,
  t,
}: Props) {
  const jobList = data;
  const form = useRef();

  const {
    register,
    formState: {
      errors,
    },
    handleSubmit,
    setError,
  } = useForm({
    resolver: yupResolver(schema),
  })

  const {
    send,
    success,
    submitting,
  } = useSendEmail({
    templateId: 'template_82vrbp6',
    errorCallback: () => setError('', { message: formSubmitErrorMessage }),
  })
  
  function onSubmit() {
    send(form.current);
  };

  return (
    <section ref={forwardRef} className="bg-concrete py-20 sm:text-xl form-section">
      <div className="container space-y-8">
        <h2 className="section-title text-center md-6 md:mb-16">
          {t('job application')}
        </h2>
        <FormSuccessMessage visible={success} />
        <Form {...{
          forwardRef: form,
          visible: !success,
          errors,
          register,
          jobList,
          loading: submitting,
          onSubmit: handleSubmit(onSubmit),
          t,
        }} />
        <div className="lg:flex lg:justify-between lg:space-x-4 space-y-4 lg:space-y-0">
          <ContactAddress data={contactDetails.address.data.address} />
          <ContactPhone data={contactDetails.number.data.number} displayLabel={false} />
          <ContactEmail data={contactDetails.email.data.email} />
        </div>
      </div>
    </section>
  )
}

export default JobApplicationForm