import { useTranslation } from 'gatsby-plugin-react-i18next';
import gsap from 'gsap';
import { TweenMax } from 'gsap/gsap-core';
import React, { useEffect, useRef } from 'react';
import BannerCareerImg from './banner-join.svg';

const BannerCareer = () => {
  const el = useRef()
  const q = gsap.utils.selector(el)
  const { t } = useTranslation()

  useEffect(() => {
    const chatBlue = q('#chat2')
    const chatGrey = q('#chat1')
    const blink = q('.blink')
    const lightBulb = q('#lightbulb')
    
    TweenMax.staggerFrom([chatBlue, chatGrey], 1, {transformOrigin: "50% 50%", y: '-=10', stagger: .5, repeat: -1, yoyo: true});

    TweenMax.staggerFrom(blink, .9, {autoAlpha: 0, scale: Math.random() * 2, transformOrigin: "50% 50%", repeat: -1, stagger: .2});

    TweenMax.to(lightBulb, 1, { fill: '#d6d6d6', repeat: -1, yoyo: true, ease: 'circ' });
  }, [])

  return (
    <section ref={el} className="banner-section h-full max-h-[768px] lg:max-h-[991px] flex flex-col">
      <div className="banner-wrapper">
        <h1 className="page-title mr-auto relative p-6 md:p-0">
          {t('join')}
          <br />
          <span className="text-primary font-bold">advisoryapps</span>
        </h1>
        <div className="banner banner-join">
          <BannerCareerImg />
        </div>
      </div>
    </section>
  )
}

export default BannerCareer;